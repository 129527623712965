import { option } from "components/input-components/select-buttons/Select-Buttons";
import { useFormik } from "formik";
import { IUser } from "models/user";
import { useState, useEffect } from "react";
import { validationSchema } from "./add-update-user-modal.validation";
import { GetUsers } from "api/user/get-users";
import { AddUser } from "api/user/add-user";
import { UpdateUser } from "api/user/update-user";
import { useAppSelector } from "store/hooks";
import { GetUserById } from "api/user/get-user-by-id";
import { convertAxiosErrorToValidationError } from "utils/response-to-validation-errors";

export interface IAddUser {
  onAdded: () => void;
  Variant: "add";
}
export interface ICommon {
  _token?: string;
  open?: boolean;
  OnCancel?: () => void;
  Shape?: "modal" | "card";
}
export interface IUpdateUser {
  Variant: "update";
  onUpdate: (UpdatedUserInfo: IUser) => void;
  UserId: string | undefined;
}

export type Params = ICommon & (IUpdateUser | IAddUser);

// TODO: refactor
const initialFormValues: any = {
  name: "",
  phone: "",
  password: "",
  menuroles: "",
  provider_id: "",
  email: "",
  age: null,
  gender: null
}

export const useLogic = (params: Params) => {
  const [isLoadingUser, setisLoadingUser] = useState(false);
  const [validationErrors, setValidationErrors] = useState("");

  const user = useAppSelector((root) => root.appReducer.user);

  const [options, setOptions] = useState<option[]>([
    { title: "user", value: "user" },
    { title: "manager", value: "manager" },
    // { title: "org admin", value: "org_admin" },
  ]);

  useEffect(() => {
    if (user?.menuroles === "admin") setOptions([...options, { title: "admin", value: "admin" }]);
  }, [user?.menuroles]);

  const ValidationSchema = validationSchema({
    validatePassword: params.Variant === "add",
    validatePrvoiderOnOrgAdminOnly: user?.menuroles !== "admin",
    ignoreProviderValidation: params.Variant === "update" && user?.id === params.UserId,
  });

  const form = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: ValidationSchema,
    initialValues: initialFormValues,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      if (params.Variant === "add") {
        AddUser({ ...values, role: values.menuroles, provider_id: values.provider_id ? values.provider_id : undefined }, (error, resp) => {
          actions.setSubmitting(false);
          if (error) {
            if (error.response?.status === 422) {
              if (error.response?.data["provider"]) form.setFieldError("provider_id", error.response?.data["provider"]);
            } else {
              if (Array.isArray(convertAxiosErrorToValidationError(error))) setValidationErrors(convertAxiosErrorToValidationError(error)?.[0] ?? "");
              setValidationErrors(error?.response?.data?.message);
            }
          } else if (resp) {
            params.onAdded();
            form.resetForm();
          }
        });
      } else if (params.Variant === "update" && params.UserId && (params.Shape === "card" || params.open)) {
        UpdateUser(
          {
            id: params.UserId,
            ...values,
            menuroles: values.menuroles as any,
            provider_id: values.provider_id ? values.provider_id : undefined,
            password: values.password || undefined,
          },
          (error, resp) => {
            actions.setSubmitting(false);
            if (error) {
              if (error.response?.status === 422) form.setFieldError("provider_id", error.response?.data["provider"]);
              if (Array.isArray(convertAxiosErrorToValidationError(error))) setValidationErrors(convertAxiosErrorToValidationError(error)?.[0] ?? "");
            } else if (resp) {
              params.onUpdate(resp);
              form.resetForm();
            }
          }
        );
      }
    },
  });

  // get user info
  useEffect(() => {
    if (params.Variant === "update" && params.UserId && (params.Shape === "card" || params.open)) {
      setisLoadingUser(true);
      // TODO: to check
      GetUserById({ id: params.UserId }, (error, resp) => {
        setisLoadingUser(false);
        if (error) {
          console.log(error.message);
        } else if (resp) {
          const { phone, provider_id, ...rest } = resp.data;
          form.setValues({
            ...rest,
            password: "",
            phone: phone ?? "",
            provider_id: provider_id,
          });
        }
      });
    }
  }, [params.Variant === "update" && params.UserId]);

  return {
    form,
    isLoadingUser,
    user,
    options,
    validationErrors,
  };
};
